import React, { useState , useEffect } from 'react'
import { Box, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

import { Session } from 'utils';
import { useServices, useSnackbar } from 'contexts';
import { getDafs } from 'services/dafService';
import { extractFieldDetailsFromDaf, pdfToTextScrape } from 'utils/pdfScrape';
import  useStore  from './store';
import FileUpload from 'components/FileUpload';
import LinearProgressBar from 'components/LinearProgressBar';
import FileUploadErrorDialog from 'dialogs/FileUploadErrorDialog';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles((theme) => ({
  container : {
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(4),
    padding:theme.spacing(3,5),
    borderRadius:theme.spacing(1),
    border:`1px solid ${theme.palette.neutral['clr-200']}`,
    width:'484px'
  }
}));

const DafUpload = ({setDafPreview}) => {
  const classes = useStyles();
  const service = useServices();
  const snackbar = useSnackbar();

  const setDafExtractedData = useStore(store => store.setDafExtractedData)
  const setDafId = useStore(store => store.setDafId)

  const [dafOneUploaded , setDafOneUploaded] = useState(false)
  const [dafOneUploading , setDafOneUploading] = useState(false)
  const [showDafOneProgressBar , setShowDafOneProgressBar] = useState(false)
  const [dafOneRefresh , setDafOneRefresh] = useState(false)
  const [dafOneError , setDafOneError] = useState(false)
  const [dafOneFieldError , setDafOneFieldError] = useState(false)
  const [errorTextDafOne , setErrorTextDafOne] = useState('It seems we’re having trouble reading the content.Please ensure that the PDF you’re uploading isn’t locked or converted from an image.')

  const [dafTwoUploaded , setDafTwoUploaded] = useState(false)
  const [dafTwoUploading , setDafTwoUploading] = useState(false)
  const [showDafTwoProgressBar , setShowDafTwoProgressBar] = useState(false)
  const [dafTwoRefresh , setDafTwoRefresh] = useState(false)
  const [dafTwoError , setDafTwoError] = useState(false)
  const [dafTwoFieldError , setDafTwoFieldError] = useState(false)
  const [errorTextDafTwo , setErrorTextDafTwo] = useState('It seems we’re having trouble reading the content.Please ensure that the PDF you’re uploading isn’t locked or converted from an image.')

  const [details, setDetails] = useState({
    isDaf2 : '',
    name: "",
    state: "",
    district: "",
    stream: "",
    prizes: "",
    sports: "",
    leadership: "",
    extraCurricular: "",
    optionalSubject: "",
    attemptTaken: "",
    educationalQualifications: "",
    employmentDetails: "",
    preferences: "",
    dafNumber : ""
  });

  useEffect(() => {
    (async () => {
      try {

        const response = await service.callService(getDafs, {
          user : Session.userId
        });

        if (response?.dafs?.[0]){
        setDafId(response.dafs[0]._id)
        }

        if (response.dafs[0].uploaded[0] === true){
          setDafOneUploaded(true)
          setDafOneRefresh(true)
        }
        if (response.dafs[0].uploaded[1] === true){
          setDafTwoUploaded(true)
          setDafTwoRefresh(true)
        }

        setDetails({
          name: response.dafs[0]?.name,
          state:  response.dafs[0]?.state,
          district: response.dafs[0]?.district,
          stream: response.dafs[0]?.stream,
          prizes: response.dafs[0]?.achievements,
          sports: response.dafs[0]?.sports,
          leadership: response.dafs[0]?.leadership,
          extraCurricular: response.dafs[0]?.interests,
          optionalSubject:  response.dafs[0]?.optionalSubjects,
          attemptTaken: response.dafs[0]?.attempts,
          educationalQualifications:  response.dafs[0]?.education?.summary,
          employmentDetails: response.dafs[0]?.experience?.summary,
          preferences: "",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, []);
  
  useEffect(() => {
    const handleUploadingState = (isUploading, setError, setProgressBar) => {
      if (isUploading) {
        setError(false);
        setProgressBar(true);
      }
    };
  
    handleUploadingState(dafOneUploading, setDafOneError, setShowDafOneProgressBar);
    handleUploadingState(dafTwoUploading, setDafTwoError, setShowDafTwoProgressBar);
  
    setDafExtractedData({
      name: details.name,
      user: Session.userId,
      uploaded: [dafOneUploaded, dafTwoUploaded],
      state: details.state,
      district: details.district,
      stream: details.stream,
      attempts: parseInt(details.attemptTaken, 10) || 0,
      achievements: details.prizes,
      interests: details.extraCurricular,
      sports: details.sports,
      leadership: details.leadership,
      optionalSubjects: details.optionalSubject,
      education: {
        summary: details.educationalQualifications,
      },
      experience: {
        summary: details.employmentDetails,
      },
    });
  }, [dafOneUploading, dafTwoUploading, details]);
  
  const handleDafScrape = async (selectedFile, isDafTwo) => {
    const setDafUploaded = isDafTwo ? setDafTwoUploaded : setDafOneUploaded;
    const setDafUploading = isDafTwo ? setDafTwoUploading : setDafOneUploading;
    const setDafError = isDafTwo ? setDafTwoError : setDafOneError;
    const setDafFieldError = isDafTwo ? setDafTwoFieldError : setDafOneFieldError;

    setDafOneError(false);
    setDafOneFieldError(false);
    setDafTwoError(false);
    setDafTwoFieldError(false);
  
    const scrapedData = await extractDafData(selectedFile, isDafTwo);
  
    if (!scrapedData) {
      setDafError(true);
      snackbar.error("Failed to extract data. Please try again.");
      return false;
    }

    const dafNumber = scrapedData.dafNumber?.trim();
    
    if (dafNumber === "DETAILED APPLICATION FORM [DAF]-I") {
     
      if (isDafTwo) {        
        setDafTwoError(true);
        setErrorTextDafTwo('We didn’t find the necessary fields in your document. Please verify the Daf Type and re-upload it.')
        return false;
      }
    } else if (dafNumber === "DETAILED APPLICATION FORM - II") {
      if (!isDafTwo) {
        setDafOneError(true);
        setErrorTextDafOne('We didn’t find the necessary fields in your document. Please verify the Daf Type and re-upload it.')
        return false;
      } 
    } 

    setDetails((prevDetails) => ({
      ...prevDetails,
      ...scrapedData,
    }));

    const requiredFields = ["name", "state", "district", "stream"];
    const missingFields = requiredFields.filter((field) => !scrapedData[field]);
  
    if (missingFields.length > 0) {
      setDafError(true);
      setDafFieldError(true);
      setErrorTextDafOne('We didn’t find the necessary fields in your document. Please verify the file and re-upload it.')
      setErrorTextDafTwo('We didn’t find the necessary fields in your document. Please verify the file and re-upload it.')
      return false;
    }

    setDafUploaded(true);
    setDafUploading(true);
    return true;
  }; 
  
  const extractDafData = async (file , isDAF2) => {
    try {  
      const extractedText = await pdfToTextScrape(file);
      if (!extractedText || extractedText.trim().length === 0) {
        return null;
      }else{
        const result = extractFieldDetailsFromDaf(extractedText, isDAF2);
        return result;
      }
    } catch (error) {
      console.error("Failed to extract text from PDF:", error);
      return null;
    }
  };

  const handleNextStep = () => {
    setDafPreview(true)
  }

  return (
    <Box display='flex' flexDirection='column' gap='16px'>
      <Box className={classes.container}>
        <Box display='flex' flexDirection='column' gap='4px'>
          <Typography variant='body01-bold' color='neutral.clr-600'>Upload DAF</Typography>
          <Typography variant='body01-medium' color='neutral.clr-600'>
            Upload both DAFs to get more personalized experience
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap='12px'>
          {(showDafOneProgressBar && dafOneUploading ) 
            ? 
              ( <Box className={classes.progressBarContainer}>
                  <LinearProgressBar 
                    fileNameReceived={dafOneUploading}
                    setFileNameReceived={setDafOneUploading}
                    isFileUploaded={dafOneUploaded}
                    fileName='Daf 1'
                    error={dafOneError}
                    setProgressBar={setShowDafOneProgressBar}
                    isCaseStudy={false}
                    setRefresh={setDafOneRefresh}
                  />
                </Box>
              ) 
            : dafOneRefresh 
              ?  ( 
                  <Box display='flex' gap='18px'>
                    <img 
                      src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/DAF.svg' 
                      alt='daf' 
                      width={44}
                      height={55}
                    />
                    <Box display='flex' flexDirection='column' gap='4px'>
                      <Typography variant='body01-semiBold'>DAF-01</Typography>
                      <FileUpload 
                        name="daf1-thumbnail"
                        label="Upload DAF-1"
                        onFileSelect={(file) => handleDafScrape(file, false)}
                        setIsFileUploaded={setDafOneUploaded}
                        uploadOnAws={false}
                        accept={['.pdf']} 
                        variant='outlined'
                        buttonSize='medium'
                        setErrors={setDafOneError}
                        buttonType={ <Typography 
                          variant='body01-link' 
                          color='primary.clr-300'
                          sx={{cursor:'pointer'}}
                      >
                        Change file?
                      </Typography>}
                    />
                     
                    </Box>
                  </Box>
                )
              : (
                <Box display='flex' flexDirection='column' gap='10px'>
                  <Typography variant='body01-bold' color='neutral.clr-900'>DAF-1 PDF file</Typography>
                  <FileUpload 
                    name="daf1-thumbnail"
                    label="Upload DAF-1"
                    onFileSelect={(file) => handleDafScrape(file, false)}
                    setIsFileUploaded={setDafOneUploaded}
                    uploadOnAws={false}
                    accept={['.pdf']} 
                    variant='outlined'
                    buttonSize='medium'
                    setErrors={setDafOneError}
                  />
                </Box>
              )
            }
            {(showDafTwoProgressBar && dafTwoUploading)
              ?  ( <Box className={classes.progressBarContainer}>
                    <LinearProgressBar 
                      fileNameReceived={dafTwoUploading}
                      setFileNameReceived={setDafTwoUploading}
                      isFileUploaded={dafTwoUploaded}
                      fileName='Daf 2'
                      error={dafTwoError}
                      setProgressBar={setShowDafTwoProgressBar}
                      isCaseStudy={false}
                      setRefresh={setDafTwoRefresh}
                    />
                  </Box>
              ) 
            : dafTwoRefresh 
              ? (
                    <Box display='flex' gap='18px'>
                    <img 
                      src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/DAF.svg' 
                      alt='daf' 
                      width={44}
                      height={55}
                    />
                    <Box display='flex' flexDirection='column' gap='4px'>
                      <Typography variant='body01-semiBold'>DAF-02</Typography>
                      <FileUpload 
                        name="daf2-thumbnail"
                        label="Upload DAF-2"
                        onFileSelect={(file) => handleDafScrape(file, true)}
                        setIsFileUploaded={setDafTwoUploaded}
                        uploadOnAws={false}
                        accept={['.pdf']} 
                        variant='outlined'
                        buttonSize='medium'
                        buttonType={  <Typography 
                          variant='body01-link' 
                          color='primary.clr-300'
                          sx={{cursor:'pointer'}}
                        >
                          Change file?
                        </Typography>}
                      />     
                    </Box>
                  </Box>
                )  
              : (
                <Box display='flex' flexDirection='column' gap='10px'>
                <Typography variant='body01-bold' color='neutral.clr-900'>DAF-2 PDF file</Typography>
                <FileUpload 
                  name="daf2-thumbnail"
                  label="Upload DAF-2"
                  onFileSelect={(file) => handleDafScrape(file, true)}
                  setIsFileUploaded={setDafTwoUploaded}
                  uploadOnAws={false}
                  accept={['.pdf']} 
                  variant='outlined'
                  buttonSize='medium'
                />
              </Box>
              )  
            }
        </Box>
        <FileUploadErrorDialog
              open={dafOneError}
              fileUploadProp={  
                <FileUpload 
                  name="daf1-thumbnail"
                  label="Upload again"
                  onFileSelect={(file) => handleDafScrape(file, false)}
                  setIsFileUploaded={setDafOneUploaded}
                  uploadOnAws={false}
                  accept={['.pdf']} 
                  variant='contained'
                  buttonSize='medium'
                  setErrors={setDafOneError}
                  sx={{width:'100%'}}
                />}
              title={<Box color='danger.clr-700'>File upload failed!</Box>}
              message={
                <Box className={classes.errorImage}>
                  <Typography variant='h6-regular' color='nuetral.clr-900'>
                    {errorTextDafOne}
                  </Typography>
                </Box>
              }
              buttonSize='medium'
              setProgressBar={setShowDafOneProgressBar}
              fileNameReceived={dafOneUploading}
              isCaseStudy={true}
              onSecondaryAction={() => {
                setDafOneError(null);
              }}
            />
          <FileUploadErrorDialog
              open={dafTwoError}
              fileUploadProp={  
                <FileUpload 
                  name="daf2-thumbnail"
                  label="Upload again"
                  onFileSelect={(file) => handleDafScrape(file, true)}
                  setIsFileUploaded={setDafTwoUploaded}
                  uploadOnAws={false}
                  accept={['.pdf']} 
                  variant='contained'
                  buttonSize='medium'
                  setErrors={setDafTwoError}
                  sx={{width:'100%'}}
                />}
              title={<Box color='danger.clr-700'>File upload failed!</Box>}
              message={
                <Box className={classes.errorImage}>
                  <Typography variant='h6-regular' color='nuetral.clr-900'>
                    {errorTextDafTwo}
                  </Typography>
                </Box>
              }
              buttonSize='medium'
              setProgressBar={setShowDafTwoProgressBar}
              fileNameReceived={dafTwoUploading}
              isCaseStudy={true}
              onSecondaryAction={() => {
                setDafTwoError(null);
              }}
            />
      </Box>
      <CustomButton
        size='medium'
        onClick={handleNextStep}
        disabled={!dafOneUploaded && !dafTwoUploaded}
      >
        Next
      </CustomButton>
    </Box>
  )
}

export default DafUpload;