export const DocumentOptions = {
  JD_RESUME :{
    _id : 'JD_&_RESUME',
    name : 'JD_&_RESUME',
    label : 'Practice with available Job descriptions & your Resume',
    img:'https://languify-assets.s3.ap-south-1.amazonaws.com/images/jd+and+resume.png'
  },
  JD : {
    _id : 'JD',
    name : 'JD',
    label : 'Practice with available Job descriptions',
    subLabel: '(if you don’t have resume)',
    img:'https://languify-assets.s3.ap-south-1.amazonaws.com/images/jd.png'
  },
  RESUME : {
    _id : 'RESUME',
    name : 'RESUME',
    label : 'Practice for Job role with your resume',
    subLabel: '(Not company specific)',
    img:'https://languify-assets.s3.ap-south-1.amazonaws.com/images/resumeU.png'
  },
  CASE_STUDY : {
    _id : 'CASE_STUDY',
    name : 'CASE_STUDY',
    label : 'Report/Form based interview',
    subLabel : '(Case study, Product teardown, Project reports, UPSC DAF & more)',
    img : 'https://languify-assets.s3.ap-south-1.amazonaws.com/images/Upsc_report.png'
  }
}

export const InterviewSteps = {
    JOB_DETAILS : {
      _id: 'JOB_DETAILS',
      name : 'Job Details'
    },
    SELECT_RESUME : {
      _id : 'SELECT_RESUME',
      name : 'Select Resume'
    },
    PREVIEW : {
      _id : 'PREVIEW',
      name : 'Preview'
    },
}

export const MockHeadingTemplate = {
  selectDocument : {
    head : 'We understand your uniqueness: let’s make your prep reflect that!' 
  },
  clientMock : {
    head : 'Ace Your Interviews with Company Focused Mock Prep',
    subHead : 'Practice with custom mock interviews assigned by your teacher, designed to mirror the unique expectations of companies'
  },
  personalizedMock : {
    head : 'Personalize Your Practice for the Companies You Target',
    subHead : 'Create personalized interviews that align with your dream company’s specific requirements and culture'
  }
}

export const ReportOptions = [
  {
    _id : 'exam',
    name : 'Detailed Application Form (DAF-1&2)'
  },
  {
    _id : 'report',
    name : 'Product Teardowns'
  },
  {
    _id : 'case_study',
    name : 'Case Study'
  },
  {
    _id : 'project',
    name : 'Project Reports'
  },
  {
    _id : 'other',
    name : 'Other'
  }
]

export const DafTimeOptions = {
  first_option : {
    _id : '20_mins',
    name : '20_minutes',
    label : '20 minutes',
    subLabel : '10 Questions',
    value : 10
  },
  second_option : {
    _id : '40_mins',
    name : '40_minutes',
    label : '40 minutes',
    subLabel : '20 Questions',
    value : 20
  },
  third_option : {
    _id : '60_mins',
    name : '60_minutes',
    label : '60 minutes',
    subLabel : '30 Questions',
    value : 30
  }
}

export const PersonalizedTemplateType = {
  exam : "exam",
  report : "report",
  resume : "resume",
  jobDescription : "jobDescription",
  jd_resume : "jd_resume",
}

export const dafTopics = [
  { 
    _id: 'allTopics', 
    topic: 'Let interviewer cover all the topics in interview',
  },
  { 
    _id: 'personal_background', 
    topic: 'Personal Background',
    description : 'Questions must explore the candidate’s family, educational, and professional background, including insights into strengths, weaknesses, and experiences.' 
  },
  { 
    _id: 'current_affairs', 
    topic: 'Current Affairs',
    description : 'Focus on recent national and international issues, with emphasis on social challenges like gender equality, poverty, education, health, and environment, particularly those relevant to Uttar Pradesh or India at large.'
  },
  // { 
  //   id: 'optional_subject', 
  //   topic: 'Optional Subject',
  //   description : `Questions should relate to the candidate’s chosen optional subject {optional_subject} and its administrative relevance. In the 'domain' field of your response, mention {optional_subject} for these type of questions`
  // },
  { 
    _id: 'hobbies&interests',
    topic: 'Hobbies and Interests',
    description: 'Questions should delve into hobbies mentioned in the DAF, exploring recent trends or innovations in these areas, and skills relevant to governance.'
  },
  { 
    _id: 'government_issues', 
    topic: 'Administrative and Governance Issues',
    description : 'Cover topics such as good governance, ethical responsibilities of civil servants, and examples of successful governance practices.'
  },
  { 
    _id: 'situational&behavioral',
    topic: 'Situational and Behavioral Questions',
    description : 'Pose real-world scenarios to assess problem-solving, decision-making, and ethical judgment.'
  },
  { 
    _id: 'india_history',
    topic: `India's History, Culture, and Heritage`,
    description : 'Include significant historical events, cultural diversity, or heritage preservation efforts, especially related to Uttar Pradesh or India.' 
  },
  { 
    _id: 'science_tech',
    topic: `Science and Technology`,
    description : 'Explore recent technological developments and their application in governance, with an emphasis on the candidate’s engineering background.' 
  },
  { 
    _id: 'economic_social', 
    topic: `Economic and Social Development`,
    description : 'Cover government schemes, unemployment, inflation, and socio-economic challenges relevant to Uttar Pradesh or India.'
  },
  { 
    _id: 'envoirnment_ecology',
    topic: `Environment and Ecology`,
    description : 'Include topics such as climate change, conservation, and India’s environmental policies or challenges.'
  },
  { 
    _id: 'internal_external_security', 
    topic: `Internal and External Security`,
    description : 'Include questions on border management, terrorism, and cybersecurity, with relevance to national policies.'
  },
  { 
    _id: 'miscellaneous', 
    topic: `Miscellaneous`,
    description : `Ask abstract, philosophical, or opinion-based questions. Include the candidate's motivations for choosing civil services and their vision as a civil servant. These do not fall under any of the above mentioned categories.`
  },
];

export const regexPatternsDAF1 = {
  name: /Name\s*:\s*([A-Za-z\s]+)(?=\s*Have\b)/,
  state: /State\s*:\s*\|\s*([A-Za-z\s]+)(?=\s*Pin\s*Code\s*:)/,
  district: /District\s*:\s*\|\s*([A-Za-z\s]+)(?=\s*State\b)/,
  stream: /Stream at Graduation Level\s*[:\-]*\s*([A-Za-z\s]+)/,
  prizes:
    /Prizes,\s*Medals,\s*Scholarships\s*etc\.\s*\|\s*((?:.|\n)*?)(?=\(b\))/,
  sports: /Sports\/N\.C\.C\.\setc\.\s*\|\s*((?:.|\n)*?)(?=\(c\))/,
  leadership:
    /Position\(s\)\s*of\s*distinction\s*Leadership\s*held\s*in\s*School\/College\s*\|\s*((?:.|\n)*?)(?=\(d\))/,
  extraCurricular:
    /Other\s*extracurricular\s*activities\s*and\s*interests\s*\(Such\s*as\s*hobbies\s*etc\.\)\s*\|\s*((?:.|\n)*?)(?=\bRoll\b)/,
  optionalSubject:
    /Optional Subject for paper VI & VII\s*\|\s*(.*?)(?=\s*(12\.|Roll))/,
  attemptTaken:
    /How many times have you appeared at the Civil Services\(Pre\) Examination so far, including the current Examination held in May, 2023\s*\|\s*([A-Za-z0-9\s]+)(?=\s*18\(b\)\.)/,
  educationalQualifications:
    /NO\s*14\.2\s*Educational Qualifications\s*:\s*Commencing with Matriculation or equivalent examination till Graduation:- \(Please upload scanned copy of all Certificates\/Degree\)\s*((?:.|\n)*?)(?=\s*15\.\(i\)\s*Have\s+you\s+ever\s+been\s+employed\?)/,
  employmentDetails:
    /Details of your employments \(in chronological order till date\)\s*((?:.|\n)*?)(?=\s*15\.\(ii\))/,
  preferences:
    /Service in Civil Services Examination, 2023\. S\.N\.\s*Name of Services\/Post\s*Order of Preference\s*((?:.|\n)*?)(?=\s*Roll\s*No\.)/,
    dafNumber:
    /UNION PUBLIC SERVICE COMMISSION([\s\S]*?)CIVIL SERVICES/,
};

export const regexPatternsDAF2 = {
  name: /Name\s*:\s*([A-Za-z\s]+)(?=\s*(Email\s*:|$))/,
  state: /State\s*:\s*\|\s*([A-Za-z\s]+)(?=\s*Pin\s*Code\s*:)/,
  district: /District\s*[\|:]\s*([A-Za-z\s]+)/,
  stream: /Stream at Graduation Level\s*[:\-]*\s*([A-Za-z\s]+)/,
  prizes:
    /Prizes,\s*Medals,\s*Scholarships\s*etc\.\s*\|\s*((?:.|\n)*?)(?=\(b\))/,
  sports: /Sports\/N\.C\.C\.\setc\.\s*\|\s*((?:.|\n)*?)(?=\(c\))/,
  leadership:
    /Position\(s\)\s*of\s*distinction\s*Leadership\s*held\s*in\s*School\/College\s*\|\s*((?:.|\n)*?)(?=\(d\))/,
  extraCurricular:
    /Other\s*extracurricular\s*activities\s*and\s*interests\s*\(Such\s*as\s*hobbies\s*etc\.\)\s*\|\s*((?:.|\n)*?)(?=\bRoll\b)/,
  optionalSubject:
    /Optional Subject for paper VI & VII\s*\|\s*(.*?)(?=\s*(12\.|Roll))/,
  attemptTaken:
    /How many times have you appeared at the Civil Services\(Pre\) Examination so far, including the current Examination held in May, 2023\s*\|\s*([A-Za-z0-9\s]+)(?=\s*18\(b\)\.)/,
    educationalQualifications:
    /4\.\s*Educational Qualifications\s*:\s*Commencing with Matriculation or equivalent examination till Graduation:-\s*((?:.|\n)*?)(?=\s*6\.\s+Having\s+regard\s+to\s+answers\s+given\s+against\s+columns\s+1)/,
  employmentDetails:
    /Details of your employments \(in chronological order till date\)\s*((?:.|\n)*?)(?=\s*15\.\(ii\))/,
  preferences:
    /Service in Civil Services Examination, 2023\. S\.N\.\s*Name of Services\/Post\s*Order of Preference\s*((?:.|\n)*?)(?=\s*Roll\s*No\.)/,
    dafNumber:
    /UNION PUBLIC SERVICE COMMISSION([\s\S]*?)CIVIL SERVICES/,
};