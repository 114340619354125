import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

import  { IconButton } from 'components/CustomButton';
import { SimpleSelect } from 'components/CustomSelectFields';
import { ReportOptions } from './constants';
import { useState } from 'react';
import CaseStudy from './CaseStudy';
import DafUpload from './DafUpload';
import DafTopicSelection from './DafTopicSelection';

const useStyles = makeStyles((theme) => ({
  whiteBoxContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(7),
    alignItems:'center',
    padding:theme.spacing(7),
    backgroundColor : theme.palette.shades['clr-white-900'],
    borderRadius: '4px',
    position:'relative',
    width:'800px',
    height:'496px'
  },
  backArrow:{
    backgroundColor:theme.palette.shades['clr-white-900'] , 
    color:theme.palette.neutral.main,
    '&:hover': {
    backgroundColor: theme.palette.shades['clr-white-900'], 
  },
  },
  reportBasedContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(6),
    justifyContent:'center',
    alignItems:'center'
  },
  header:{
    display:'flex',
    gap:theme.spacing(1),
    height:'52px',
    width:'734px',
    gap:theme.spacing(13.5),
    alignItems:'center',
    borderBottom : `1px solid ${theme.palette.neutral['clr-200']}`
  },
  bottomContentContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(3.5)
  },
  scrollContainer:{
    display:'flex',
    flexDirection:'column',
    gap: theme.spacing(4),
    overflowY:'auto',
    width:'100%',
    alignItems:'center',
    maxHeight:'364px',
    height:'364px'
  }
}))


const ReportBasedInterview = ({
  setOpenSelectDocumentOptions,
  setOpenReportBasedInterview,
}) => {
  const classes = useStyles();

  const [ reportOption , setReportOption ] = useState('')
  const [dafPreview , setDafPreview] = useState(false)
  const [ interviewTitle , setInterviewTitle ] = useState('Report based Interview')

  useEffect(()=> {
    if(reportOption._id === ReportOptions[0]._id ){
      setInterviewTitle('UPSC Mock Interview')
    }
  },[reportOption])

  const handleBackButton = ()=>{
    if(dafPreview){
      setDafPreview(false)
    }else{
      setOpenSelectDocumentOptions(true)
      setOpenReportBasedInterview(false)
    } 
  }

  return (
    <Box className={classes.whiteBoxContainer}>
        <Box className={classes.reportBasedContainer}>
          <Box className={classes.header}>
            <IconButton
              variant='contained'
              className={classes.backArrow}
              onClick={handleBackButton}
            >
                <img 
                  src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/arrow-left-icon.png'  
                  alt='back'
                />
            </IconButton>
            <Typography variant='h4-medium' color='neutral.clr-600'>
               {interviewTitle}
            </Typography>
          </Box>
          {dafPreview 
            ? (
               <DafTopicSelection />
              ) 
            : (
                <Box className={classes.scrollContainer}>
                  <SimpleSelect
                      value={reportOption}
                      options={ReportOptions}
                      label='Document type'
                      sx={{width: '484px', height:'48px'}}
                      menuStyle= {{width: '484px'}}
                      width='484px'
                      onSelect={(data)=> setReportOption(data)}
                      disabled={reportOption}
                    />
                  {reportOption && reportOption._id !== ReportOptions[0]._id && (
                      <CaseStudy />
                    )}
                  {reportOption && reportOption._id === ReportOptions[0]._id && (
                    <DafUpload setDafPreview={setDafPreview} />
                    )}
                </Box>
              )}
        </Box>
       
    </Box>
  )
}

export default ReportBasedInterview