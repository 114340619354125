import React from 'react';
import Box from '@mui/material/Box';
import useStore from './store';
import {shallow} from 'zustand/shallow';
import CustomButton from 'components/CustomButton';
import RetakeIcon from '@mui/icons-material/Cached';

import { useSnackbar } from 'contexts';
import { getFeedbackAndAnswer } from 'services';
import { Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '4px',
        backgroundColor: theme.palette.neutral['clr-50'],
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        width: '100%',
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.shades['clr-white-900'],
        border: '1px solid',
        borderColor: theme.palette.neutral['clr-300']
    }
}));

function PersonalizedFeedback() {
    const snackbar = useSnackbar();
    const classes = useStyles();
    const activeQuestion = useStore(state => state.questions?.[state.currentQuestion]);
    const currentQuestionIndex = useStore(state => state.currentQuestion, shallow);
    const questions = useStore(state => state.questions, shallow);
    const setQuestions = useStore(state => state.setQuestions, shallow);
    const template = useStore(state => state.template, shallow);

    const [loading, setLoading] = React.useState(false);
    const [generate, setGenerate] = React.useState(false);

    let { personalizedAnswer, personalizedFeedback } = React.useMemo(() => {
        return {
            personalizedAnswer: questions[currentQuestionIndex]?.personalizedAnswer,
            personalizedFeedback: questions[currentQuestionIndex]?.content.feedback,
        };
    }, [
        questions[currentQuestionIndex]?.personalizedAnswer,
        questions[currentQuestionIndex]?.content?.feedback,
        currentQuestionIndex, questions
    ]);

    const generateFeedbackAndAnswer = async () => {
        setLoading(true);
        try {
            const result = await getFeedbackAndAnswer(
                activeQuestion?.answerId,
                template?.domain,
                template.pathway ? true : false,
                template.metadata?.personalizedTemplateType === "exam" 
                  ? questions[currentQuestionIndex]?.metadata?.domainName : null
            );
            if (result) {
                const updatedQuestions = [...questions];
                updatedQuestions[currentQuestionIndex].personalizedAnswer = result.personalizedAnswer;
                updatedQuestions[currentQuestionIndex].content.feedback = result.personalizedFeedback;
                setQuestions(updatedQuestions);
                setGenerate(false);
            }
        } catch (error) {
            console.error("generate feedback error :- ", error);
            snackbar.error('Oo oh! unable to generate feedback, try again');
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (activeQuestion.type === 'technical' && !activeQuestion.content.feedback) {
            generateFeedbackAndAnswer();
        }

        if (activeQuestion.type === 'situational' &&
            !(activeQuestion.content.feedback || activeQuestion.personalizedAnswer)) {
            generateFeedbackAndAnswer();
        }
    }, [currentQuestionIndex, questions,activeQuestion]);

    return (
        (!generate && !loading && personalizedFeedback) ?
            <Box className={classes.root} id='improvement-improved-response-container'>
                <Typography variant='h6-semiBold' color='success.clr-500' mb={1}
                    id='improvement-improved-response'
                >
                    Tips for improvement
                </Typography>
                <Typography variant='body01-medium' color='neutral.clr-900' mb={3}>
                    {personalizedFeedback}
                </Typography>

                {
                    (personalizedAnswer) && <>
                        <Typography variant='h6-semiBold' color='success.clr-500' mb={1}>
                            Improved Response
                        </Typography>
                        <Typography variant='body01-medium' color='neutral.clr-900'>
                            {personalizedAnswer}
                        </Typography>
                    </>
                }
            </Box> : (
                (loading && !personalizedFeedback) ?
                    <Box>
                        <Skeleton width={'50%'} height={'40px'} />
                        <Skeleton width={'100%'} variant="rounded" height={'120px'} />
                        <br />
                        <Skeleton width={'50%'} height={'40px'} />
                        <Skeleton width={'100%'} variant="rounded" height={'120px'} />
                    </Box> : <Box className={classes.error}>
                        <Typography
                            variant='h5-medium' color='neutral.clr-500'
                            style={{ textAlign: 'center', lineHeight: '32px' }} mb={5}
                        >
                            Looks like feedback not generated!  <br />
                            Click button below to regenerate feedback :)
                        </Typography>
                        <CustomButton
                            variant='outlined'
                            startIcon={<RetakeIcon fontSize='small' />}
                            onClick={generateFeedbackAndAnswer}
                        >
                            Click to generate
                        </CustomButton>
                    </Box>
            )
    )
}

export default PersonalizedFeedback;
