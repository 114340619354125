import React, { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import CustomButton from './CustomButton';
import { uploadFile } from "services/uploadService";
import { useSnackbar } from "contexts";

const ChooseFileButton = ({ 
  name='btn-upload', 
  onFileSelected, 
  accept = ["application/pdf"], 
  disabled, 
  label , 
  buttonSize , 
  variant ,
  buttonType = <CustomButton
                  variant={variant}
                  component="span"
                  disabled={disabled}
                  size={buttonSize}
                >
                  {label}
                </CustomButton>
}) => {
  return (
    <label htmlFor={name}>
      <input
        id={name}
        name={name}
        style={{ display: 'none' }}
        type="file"
        onChange={onFileSelected}
        accept={accept.join(",")}
        disabled={disabled}
      />
      {buttonType}
    </label>
  );
};

export default function FileUpload({
  name,
  uploadLocation,
  file = { name: undefined, url: "" },
  toPublic = false,
  accept = [],
  onUpload = () => { },
  onUploadCancelled = () => { },
  setErrors = () => { },
  readOnly = false,
  label = 'Choose file',
  uploadOnAws,
  fileNames=[],
  onFileSelect = () => {
    return true;
  },
  buttonSize='small',
  variant='contained',
  buttonType,
  ...props
}) {
  const snackbar = useSnackbar();

  const [selectedFile, setSelectedFile] = React.useState(file);
  const [fileDataUpload, setFileDataUpload] = React.useState();
  const [scrapedDataReceived , setScrapedDataReceived] = React.useState(false)

  const selectFile = (event) => {
    const file = event.target.files[0];
    if (file?.size > 2 * 1024 * 1024 && uploadOnAws) {
      snackbar.error('File size exceeds the limit of 2MB.');
      return;
    }

    setSelectedFile(file);
    const isScraped = onFileSelect(file);
    setScrapedDataReceived(isScraped);
    
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;
      setFileDataUpload(fileContent); 
    };
    reader.readAsDataURL(file);
  };

  const handleUploadSuccess = (uploadedFile) => {
    if (onUpload && typeof onUpload === 'function') onUpload(uploadedFile);
  };

  useEffect(() => {
    const uploadFileIfNeeded = async () => {
      try {
        if (uploadOnAws && fileDataUpload && selectedFile && !selectedFile.url) {

          if (scrapedDataReceived) {
            try {
              const url = await uploadFile(selectedFile, fileDataUpload, uploadLocation, toPublic);
              const uploadedFile = {
                name: selectedFile.name,
                url,
              };
              handleUploadSuccess(uploadedFile);
            } catch (uploadError) {
              setErrors(true);
              throw new Error(`Upload failed: ${uploadError.message}`);
            }
          } else {
            setErrors(true);
          }
        }
      } catch (e) {
        console.error(e);
        setErrors(true)
      }
    };

    if (uploadOnAws) {
      uploadFileIfNeeded();
    }
  }, [fileDataUpload, selectedFile]);

  const hasUploadLocation = useMemo(() => {
    return !!uploadLocation;
  }, [uploadLocation]);

  return (
    <Box { ...props}>
        <ChooseFileButton
          onFileSelected={selectFile}
          accept={accept}
          label={label}
          name={name}
          buttonSize={buttonSize}
          variant={variant}
          buttonType={buttonType}
        />
    </Box>
  );
}
