import React , {useEffect , useState} from 'react'
import makeStyles from "@mui/styles/makeStyles";
import { Box, Tooltip, Typography } from '@mui/material';

import { AutoCompleteSelect } from 'components/CustomSelectFields';
import { useServices, useSnackbar } from 'contexts';
import { createPersonalizedTemplate, getDomains } from 'services';
import { TextArea } from 'components/CustomFields';
import { PersonalizedTemplateType } from './constants';
import CustomButton from 'components/CustomButton';
import pdfToText from 'react-pdftotext';
import FileUpload from 'components/FileUpload';
import LinearProgressBar from 'components/LinearProgressBar';
import StartPersonalizedAssessmentDialog from 'dialogs/StartPersonalizedAssessmentDialog';
import FileUploadErrorDialog from 'dialogs/FileUploadErrorDialog';

const useStyles = makeStyles((theme) => ({
  container : {
    display:'flex',
    flexDirection:'column',
    width:'484px',
    gap:theme.spacing(4)
  },
  uploadCaseBox:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    gap:theme.spacing(3),
    width:'100%',
    height:'132px',
    borderRadius:theme.spacing(1),
    border:`1px solid ${theme.palette.neutral['clr-200']}`
  },
  bottomBox:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(2),
    justifyContent:'center',
    alignItems:'center',
    width:'100%'
  },
  errorImage:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  tooltip: {
    backgroundColor: theme.palette.shades['clr-white-900'],
    color: theme.palette.neutral['clr-900'], 
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', 
    borderRadius: '4px',
    padding: theme.spacing(1,2),
    ...theme.typography['body02-bold'],
    minWidth:'490px',
    height:'35px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    border:`1px solid ${theme.palette.neutral['clr-500']}`
  },
  arrow: {
    color: theme.palette.shades['clr-white-900'],
    '&::before': {
      border:`1px solid ${theme.palette.neutral['clr-500']}`,  
      backgroundColor: theme.palette.shades['clr-white-900'],   
      boxSizing: 'border-box', 
    },
  },
}))

const CaseStudy = () => {
  const classes = useStyles();
  const service = useServices();
  const snackbar = useSnackbar()

  const [domains, setDomains] = useState();
  const [selectedDomain , setSelectedDomain] = useState('')
  const [error , setError] = useState(false)
  const [openPdf , setOpenPdf] = useState(true)
  const [report , setReport] = useState('')
  const [selectedFile, setSelectedFile] = useState({ name: undefined, url: "" });
  const [isFileUploaded , setIsFileUploaded] = useState(false)
  const [fileName , setFileName] = useState('')
  const [fileNameReceived , setFileNameReceived] = useState(false)
  const [scrapedData , setScrapedData] = useState('')
  const [progressBar , setProgressBar] = useState(false)
  const [progressionComplete , setProgressionComplete] = useState(false)
  const [finalReport , setFinalReport] = useState('')
  const [assessmentDialogOpen , setAssessmentDialogOpen] = useState(false)
  const [isResponseReceived , setIsResponseReceived] = useState(false)
  const [interviewError , setInterviewError] = useState(false)
  const [template , setTemplate] = useState()

  useEffect(()=> {
    if(error){
      setFileNameReceived(false)
    }else if(scrapedData){
      setFinalReport(scrapedData)
    }else{
      setFinalReport(report)
    }
  },[scrapedData , report , error])
  
  useEffect(() => {
    const fetchDomains = async () => {
      try {            
        const domains = await service.callService(getDomains);
        setDomains(domains.interviewDomains.sort((a, b) => a.name.localeCompare(b.name)));
        
      } catch (err) {
        setError(err.message);
      } 
    };

    fetchDomains();
  }, []);

  useEffect(()=> {
    if(fileNameReceived){
      setError(false)
      setProgressBar(true)
    }
  },[fileNameReceived])

  const handlePdfUpload = () => {
    setOpenPdf(!openPdf)
    setReport('')
    setScrapedData('')
    setFileNameReceived(false)
    setProgressionComplete(false)
  }

  const handleReportChange = (e) => {
    setReport(e.target.value)
  }

  const handlePdfScrape = async(selectedFile) => {
    const scrapedData = await extractResumeData(selectedFile);
    if(scrapedData){
      setScrapedData(scrapedData)
      let fileNameWithoutExtension = selectedFile.name.replace(/\.pdf$/i, '');
      setFileName(fileNameWithoutExtension)
      setFileNameReceived(true)
      setIsFileUploaded(true)
     
      return true;
    }
  }

  const extractResumeData = async (file) => {
    try {
      const extractedText = await pdfToText(file);
      if (!extractedText || extractedText.trim().length === 0) {
        setError(true)
        return null;
      }else{
        return extractedText;
      }
    } catch (error) {
      setError(true)
      console.error("Failed to extract text from PDF:", error);
      return null;
    }
  };

const handleBeginInterview = async () => {
  try {
    setInterviewError(false)
    setAssessmentDialogOpen(true)

    const response = await service.callService(createPersonalizedTemplate, {  
      domain : selectedDomain._id,
      personalizedTemplateType : PersonalizedTemplateType.report,
      report: finalReport
     });
    
    if(response){
      setIsResponseReceived(true)
      setTemplate(response.interviewTemplate)
    }
  } catch (err) {
    console.error("Error while creating personalized template:", err.message);
    snackbar.error(err.message);
    setInterviewError(true)
  }
};

  return (
    <Box className={classes.container}>
        <Box className={classes.bottomContentContainer}>
            <AutoCompleteSelect
              options={domains || []}
              value={selectedDomain}
              label='Domain'
              style={{ width: '484px' , height:'48px'}}
              placeholder='Select Domain'
              onSelect={(data)=>setSelectedDomain(data)}
              menuStyle={{maxHeight: '200px'}}
              width='484px'
            />
          </Box>
          {openPdf 
            ? (
                <Box className={classes.uploadCaseBox}>
                   {(progressBar && fileNameReceived ) 
                    ? 
                      ( <Box className={classes.progressBarContainer}>
                          <LinearProgressBar 
                            fileNameReceived={fileNameReceived}
                            setFileNameReceived={setFileNameReceived}
                            isFileUploaded={isFileUploaded}
                            fileName={fileName}
                            error={error}
                            setProgressBar={setProgressBar}
                            isCaseStudy={true}
                            setProgressionComplete={setProgressionComplete}
                          />
                           {progressionComplete && (
                            <Box 
                                sx={{width:'100%' , display:'flex' , justifyContent:'flex-end' }}
                              >
                                <Typography 
                                  variant='body01-link' 
                                  color='primary.clr-300'
                                  sx={{cursor:'pointer'}}
                                  onClick={()=>{  
                                    setProgressBar(false) 
                                    setIsFileUploaded(false)
                                    setFileNameReceived(false)
                                    setScrapedData('')
                                    setProgressionComplete(false)
                                  }}
                                >
                                  Change case study?
                                </Typography>
                              </Box>
                            )}
                          </Box>
                      ) 
                    : 
                      (
                        <>
                          <Box sx={{display:'flex' , alignItems:'center', gap:'4px'}}>
                            <Typography variant='h6-medium' color='neutral.clr-900'>
                              Upload case study
                            </Typography>
                            <Typography variant='body02-bold' color='neutral.clr-600'>(Only PDF)</Typography>
                          </Box>
                          <FileUpload 
                            name="thumbnail"
                            label="Upload"
                            file={selectedFile}
                            onFileSelect={handlePdfScrape}
                            setIsFileUploaded={setIsFileUploaded}
                            uploadOnAws={false}
                            setErrors={setError}
                            accept={['.pdf']} 
                          />
                      
                      </>
                    )}
                  
                </Box>
              )
            : 
              (
              <TextArea
                label='Report content'
                rows={4}
                placeholder='Paste the content here...'
                sx={{
                  width: '484px',
                }}
                value={report}
                onChange={handleReportChange}
              />
            )}
          <Box className={classes.bottomBox}>
            <Tooltip 
              title={`Creating a new interview will use 1 credit from your available credits`}
              arrow 
              placement="top"
              PopperProps={{
                modifiers: [
                  {
                    name: 'flip',
                    enabled: false, 
                  },
                ],
              }}
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            >
              <Box>
                <CustomButton 
                    variant='contained' 
                    size='medium'
                    sx={{width:'484px'}}
                    onClick={handleBeginInterview}
                    disabled={!selectedDomain || !finalReport }
                  >
                    Create Interview
                  </CustomButton>
              </Box>
            </Tooltip>
              <Typography  
              variant='body01-link' 
              color='primary.clr-300' 
              sx={{cursor:'pointer'}}
              onClick={handlePdfUpload}
            >
              {openPdf ? 'Want to paste the report content? Click here' 
                       : 'Want to upload a report PDF? Click here'}
            </Typography>
          </Box>
          <StartPersonalizedAssessmentDialog 
            openDialog={assessmentDialogOpen} 
            onClose={()=>  setAssessmentDialogOpen(false)} 
            responseReceived={isResponseReceived}
            template={template}
            error={interviewError}
          />
         <FileUploadErrorDialog
            open={error}
            fileUploadProp={  
              <FileUpload 
                name="thumbnail"
                label="Upload"
                file={selectedFile}
                onFileSelect={handlePdfScrape}
                setIsFileUploaded={setIsFileUploaded}
                uploadOnAws={false}
                setErrors={setError}
                accept={['.pdf']} 
                buttonSize='medium'
                sx={{width:'100%'}}
              />}
            title={<Box color='danger.clr-700'>File upload failed!</Box>}
            message={
              <Box className={classes.errorImage}>
                <Typography variant='h6-regular' color='nuetral.clr-900'>
                  Oops! We couldn't upload your file. It seems we’re having trouble reading the content. 
                  If your PDF was converted from an image, try using a text-based file instead.
                </Typography>
              </Box>
            }
            buttonSize='medium'
            setProgressBar={setProgressBar}
            fileNameReceived={fileNameReceived}
            isCaseStudy={true}
            onSecondaryAction={() => {
              setError(null);
            }}
          />
    </Box>
  )
}

export default CaseStudy