import React, { useState , useEffect } from 'react';
import { Box, Typography, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { useTheme } from '@mui/styles';
import makeStyles from "@mui/styles/makeStyles";

import { RadioSelect } from 'components/CustomSelectFields';
import { useServices, useSnackbar } from 'contexts';
import { createPersonalizedTemplate } from 'services';
import { DafTimeOptions, dafTopics, PersonalizedTemplateType } from './constants';
import CustomButton from 'components/CustomButton';
import useStore from './store';
import StartPersonalizedAssessmentDialog from 'dialogs/StartPersonalizedAssessmentDialog';

const useStyles = makeStyles((theme) => ({
  parentContainer : {
    display:'flex',
    width:'100%',
    justifyContent:'center',
    height:'300px',
    maxHeight:'364px',
    overflowY : 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: '484px',
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  checkbox: {
    color: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  formControlLabel: {
    height: '48px',
    alignItems: 'center', 
    margin: 0, 
  },
  label: {
    ...theme.typography['body01-bold'],
    color: theme.palette.text.primary,
  },
  bottomButton : {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    height:'104px',
    position:'absolute',
    width:'100%',
    boxShadow: `0px 0px 8px 0px #00000040`,
    top:'416px',
    right:'0px',
    backgroundColor:theme.palette.shades['clr-white-900']
  },
  tooltip: {
    backgroundColor: theme.palette.shades['clr-white-900'],
    color: theme.palette.neutral['clr-900'], 
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', 
    borderRadius: '4px',
    padding: theme.spacing(1,2),
    ...theme.typography['body02-bold'],
    minWidth:'490px',
    height:'35px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    border:`1px solid ${theme.palette.neutral['clr-500']}`
  },
  arrow: {
    color: theme.palette.shades['clr-white-900'],
    '&::before': {
      border:`1px solid ${theme.palette.neutral['clr-500']}`,  
      backgroundColor: theme.palette.shades['clr-white-900'],   
      boxSizing: 'border-box', 
    },
  },
}));

const DafTopicSelection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const service = useServices();
  const snackbar = useSnackbar();

  const dafExtractedData = useStore(store => store.dafExtractedData)
  const dafId = useStore(store => store.dafId)
  
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(DafTimeOptions.first_option._id);
  const [numberOfQuestions, setNumberOfQuestions] = useState(DafTimeOptions.first_option.value);
  const [topics , setTopics] = useState([])
  const [checkBoxState, setCheckBoxState] = useState({
    allTopics: true,
  });

  const [openAssessmentDialog , setOpenAssessmentDialog] = useState(false)
  const [template , setTemplate] = useState()
  const [ isResponseReceived , setIsResponseReceived] = useState(false)
  const [interviewError , setInterviewError] = useState(false)

  useEffect(() => {
    const updatedTopics = [...dafTopics];
    if (dafExtractedData.optionalSubjects) {
      updatedTopics.push({
        _id: 'optional_subject',
        topic: dafExtractedData.optionalSubjects,
        description: `Questions should relate to the candidate’s chosen optional subject (${dafExtractedData.optionalSubjects}) and its administrative relevance. In the 'domain' field of your response, mention ${dafExtractedData.optionalSubjects} for these types of questions.`,
      });
    }
    setTopics(updatedTopics);
  }, []);

  const handleChangeTimeSlot = (e) => {
    const timeSlot = e.target.value;
    setSelectedTimeSlot(timeSlot);

    const selectedOption = Object.values(DafTimeOptions).find((option) => option._id === timeSlot);
    if (selectedOption) {
      setNumberOfQuestions(selectedOption.value); 
    }
  };

  const selectedTopics = checkBoxState.allTopics
    ? topics.filter((topic) => topic._id !== 'allTopics') 
    : topics.filter((topic) => checkBoxState[topic._id]);

  const handleCheckboxChange = (id) => (event) => {
    const checked = event.target.checked;
  
    if (id === 'allTopics') {
      if (checked) {
        setCheckBoxState({
          allTopics: true,
        });
      } else {
        setCheckBoxState({
          allTopics: false,
        });
      }
    } else {
      setCheckBoxState((prevState) => ({
        ...prevState,
        allTopics: false, 
        [id]: checked,
      }));
    }
  };  

  const createDafInterview = async() => {
   try {
      setInterviewError(false)
      setOpenAssessmentDialog(true)


      const dataToSend = {};

      dataToSend.personalizedTemplateType = PersonalizedTemplateType.exam
      dataToSend.totalQuestions = numberOfQuestions
      dataToSend.daf = {...dafExtractedData}

      if(dafId){
        dataToSend.daf = {...dafExtractedData , _id : dafId}
      }
      dataToSend.templateDetails = {
        topics : selectedTopics
      }

      const response = await service.callService(createPersonalizedTemplate, { ...dataToSend });

      if(response){
        setIsResponseReceived(true)
        setTemplate(response.interviewTemplate)
      }
   } catch (err) {
    console.error("Error while creating personalized template:", err.message);
    snackbar.error(err.message);
    setInterviewError(true)
    setOpenAssessmentDialog(false)
   }
  }

  const handleDialogClose = () => {
    setOpenAssessmentDialog(false)
  }

  return (
    <Box className={classes.parentContainer}>
      <Box className={classes.container}>
        <Box borderBottom={`1px solid ${theme.palette.neutral['clr-400']}`} pb={3.5}>
          <RadioSelect
            options={Object.values(DafTimeOptions)}
            value={selectedTimeSlot}
            onChange={handleChangeTimeSlot}
            optionWidth={137}
            label='Select interview duration'
          />
        </Box>
        <Box display='flex' flexDirection='column' gap='16px'>
          <Box display='flex' flexDirection='column' gap='8px'>
            <Typography variant='h5-medium'>
              Topics for interview questions
            </Typography>
            <Typography variant='body01-bold' color='neutral.clr-600'>
              Questions will be asked on the selected topics. You can choose one or more topics to prepare for.
            </Typography>
          </Box>
          <Box className={classes.checkBoxContainer}>
          {topics.map((topic) => (
            <FormControlLabel
              key={topic._id}
              control={
                <Checkbox
                  checked={checkBoxState[topic._id] || false}
                  onChange={handleCheckboxChange(topic._id)}
                  className={classes.checkbox}
                  disabled={checkBoxState.allTopics && topic._id !== 'allTopics'} 
                />
              }
              classes={{ root: classes.formControlLabel, label: classes.label }}
              label={topic._id === 'optional_subject' ? 'Optional Subject' : topic.topic}
              style={{
                borderBottom: topic._id === 'allTopics' ? `1px solid ${theme.palette.neutral['clr-200']}` : 'none',
                paddingBottom: topic._id === 'allTopics' ? theme.spacing(2) : 0,
              }}
            />
          ))}
          </Box>
        </Box>
        <Box className={classes.bottomButton}>
          <Tooltip 
            title={`Creating a new interview will use 1 credit from your available credits`}
            arrow 
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: 'flip',
                  enabled: false, 
                },
              ],
            }}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <Box>
              <CustomButton
                sx={{width:"484px"}}
                size='medium'
                onClick={createDafInterview}
                disabled={!Object.values(checkBoxState).some((value) => value)}
              >
                Create Interview
              </CustomButton>
            </Box>
          </Tooltip>
           <Typography variant='body01-semiBold' color='neutral.clr-600' mt={1}>
              Once the interview is created, you can reattempt it from “Interview Wizard” page
            </Typography>
        </Box>
      </Box>
      <StartPersonalizedAssessmentDialog 
          openDialog={openAssessmentDialog} 
          onClose={handleDialogClose} 
          responseReceived={isResponseReceived}
          template={template}
          error={interviewError}
          isDaf={true}
        />
    </Box>
  );
};

export default DafTopicSelection;
