import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import Box  from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import theme from 'theme';
import {FormControlLabel, InputAdornment, Radio, RadioGroup, TextField} from '@mui/material';
import { ListSubheader } from '@mui/material';

import countries from 'assets/countries';
import TitleTooltip from './TitleTooltip';
import Chip from './Chip';
import { BootstrapTooltip } from './InfoIconWithTooltip';
import { DocumentOptions } from './CreateInterview/constants';

export const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography['body01-bold'],
    textTransform: 'capitalize', marginBottom: theme.spacing(2)
  },
  ".MuiSelect-nativeInput": {
    height: 40,
  },
  ".MuiSelect-root": {
    ...theme.typography['body01-medium'],
    height: '44px',
    '&:hover': {
      backgroundColor: theme.palette.neutral['clr-50']
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.neutral['clr-200'],
      '& fieldset': {
        border: '2px solid',
      },
    }
  },
  ".MuiSelect-select": {
    "& fieldset": {
      border: '1px solid',
      borderColor: theme.palette.neutral['clr-500']
    },
    "&:hover": {
      "& fieldset": {
        border: '1px solid',
      },
    },
  },
  paper: {
    boxShadow: "0px 2px 8px 0px #00000029",
  },
  groupList: (props) =>( {
    maxHeight: 300,
    overflowY: "auto",
    padding: '0px',
    zIndex:5,
    "& li": {
      ...theme.typography['body01-medium'],
      height: '40px',
      borderBottom: `1px solid ${theme.palette.neutral['clr-200']}`,
      width: props.width || '100%',
      overflow: "hidden",
    },
    "& li:hover": {
      backgroundColor: theme.palette.primary['clr-50'],
      height:'auto',
      minHeight : '40px'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral['clr-200'],
        borderColor: theme.palette.neutral['clr-200'],
        '& fieldset': {
          border: '2px solid',
          borderColor: theme.palette.primary['clr-600'],
        },
      }
    },
    "& li.Mui-selected": {
      backgroundColor: `${theme.palette.primary['clr-400']} !important`,
      color: theme.palette.shades['clr-white-900']
    },
    "& li.Mui-selected:hover": {
      backgroundColor: theme.palette.primary['clr-400'],
      color: theme.palette.shades['clr-white-900']
    }
  }),
  category: {
    backgroundColor: '#fcf8f8', 
    height: 40, display: 'flex', alignItems: 'center',
    color: theme.palette.primary.main,
    fontWeight: '600', 
    border: '2px solid',
    borderColor: theme.palette.neutral.main,
    fontSize: '16px !important',
    borderWidth: '2px 0px 2px 0px',
  },
  radioSelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
    flexWrap:'wrap'
  },
  radioSelectOption: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border:  (props) =>(props.documentSelect ? `2px solid #CCD4DE` : ''),
    color: theme.palette.neutral['clr-600'],
    transition: 'box-shadow 0.3s ease', 
    '&.selected': {
      border: (props) =>(props.documentSelect ? `2px solid ${theme.palette.primary.main}` : '' ),
      color: theme.palette.primary['clr-300'],
      '&:hover $optionImage': {
        bottom: '-28px', 
      },
    },
    '&:not(.selected)': {
      '&:hover': {
        boxShadow: (props) =>(props.documentSelect ? '0px 2px 8px 0px #00000029' : ''),
      },
      '&:hover $optionImage': {
        bottom: '-12px', 
        boxShadow: '0px 0px 8px 0px #1961BF4D'
      },
    },
  },
  radioSelectLabel: {
    ...theme.typography['body01-bold'],
    minWidth:  (props) =>(props.documentSelect ?  '192px' : ''),
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.neutral['clr-600'],
    '&.selected': {
      color: theme.palette.primary['clr-300'],
    },
    position:'relative'
  },
  radioLabelConatiner: {
    display: 'flex',
    flexDirection: 'row',
    width: (props) =>(props.documentSelect ? '192px' : '' ),
  },
  radioImage: {
    padding: theme.spacing(3.5, 2),
    borderBottom: `1px solid ${theme.palette.neutral['clr-200']}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  optionImageBox:{
    position:'absolute',
    maxHeight:'92px',
    overflowY:'hidden',
    top:'12px',
    width:'96px',
    display:'flex',
    justifyContent:'center'
  },
  optionImage: {
    position:'relative',
    bottom:'-28px',
    transition: 'bottom 0.5s ease', 
  },
  textField: {
    display: 'block',
    borderRadius: theme.spacing(1),
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor : theme.palette.neutral['clr-600']
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '16px',
      color: `${theme.palette.neutral['clr-700']} !important`,
      height: 'fit-content',
      ...theme.typography['body01-medium'],
      padding: theme.spacing(3,2),
      backgroundColor: theme.palette.shades['clr-white-900']
    },
    '& .MuiOutlinedInput-root': {
      '&:hover' : {
        backgroundColor: theme.palette.shades['clr-white-900']
      },
      height: '36px !important',
      borderRadius: theme.spacing(1),
      fontSize: 16,
      '& fieldset': {
        borderColor: theme.palette.neutral['clr-200'],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.neutral['clr-200'],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary['clr-500'],
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral['clr-200'],
        '& fieldset': {
          border: '2px solid',
          borderColor: theme.palette.neutral['clr-400'],
        },
      }
    },
  },
  overflowText: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  truncatedTypography: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '1.4em', 
    ...theme.typography['body01-medium']
  },
  overflown: {
    '&:hover': {
      whiteSpace: 'normal',
      WebkitLineClamp: 2, 
      maxHeight: '2.8em', 
      ...theme.typography['body01-medium'],
      margin:theme.spacing(1,0)
    },
  },
  contentContainer:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    gap:theme.spacing(1)
  }
}));


export function SimpleSelect({
  options, defaultValue, value, onSelect = () => { }, label = null, placeholder = 'Select option',
  loading = false, ...props
}) {
  const classes = useStyles({width: props?.menuStyle?.width});

  const menuProps = React.useMemo(() => ({
    classes: {
      paper: classes.paper,
      list: classes.groupList
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    MenuListProps: {
      style: {
        ...props.menuStyle
      }
    }
  }), []);

  const handleSelection = (e) => {
    const selectedIdData = options.find(data => data._id === e.target.value)
    if(selectedIdData){
      onSelect(selectedIdData)
    }
  }

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon className={props.className + " " + classes.icon} />
    )
  };

  return (
    <FormControl className={classes['.MuiFormControl-root']}>
      {
        label && <Typography className={classes.label}>{label}</Typography>
      }
      <>
        {
          loading ? (
            <SelectSkeleton />
          ) : (
            <TitleTooltip
              placement='bottom' 
              component= {value?.name}
              disableHoverListener={value?.name?.length < 22}
              positionStyle={{ top: '-10px !important', left: '0px !important' , zIndex:0 }}
            >
              <MuiSelect
                displayEmpty
                className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} ${classes['.MuiSelect-select']}`}
                sx={{ minWidth: 200, ...(props.sx ? props.sx : {}) }}
                onChange={handleSelection}
                renderValue={(selected) => {
                  const data = options.find((option) => option._id === selected);
                  if (!selected?.length) {
                    return placeholder;
                  } else {
                    return data?.name;
                  }
                }}
                value={value?._id}
                defaultValue={defaultValue?._id}
                inputProps={{ className: classes.input }}
                MenuProps={menuProps}
                IconComponent={iconComponent}
                {...props}
                id="select"
              >
                {options?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    <Box className={classes.overflowText}>
                      <Typography
                        className={`${classes.truncatedTypography} ${option.name.length > 22 ? classes.overflown : ''}`}
                      >
                        {option.name}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </MuiSelect>
            </TitleTooltip>
          )
        }
      </>
    </FormControl>
  );
}

const SelectSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" width="200px" height='44px' style={{ borderRadius: '4px' }} />
    </Box>
  )
}

export function GroupSelect({
  data, value, onSelect = () => { }, label = 'Select', hasNotification = false,
  showNotification = false, loading = false, width,
  ...props
}) {
  const classes = useStyles({width});

  const menuProps = React.useMemo(() => ({
    classes: {
      paper: classes.paper,
      list: classes.groupList
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
  }), []);

  const { cat1, cat2 } = React.useMemo(() => ({
    cat1: data[0],
    cat2: data[1]
  }), [data]);

  const handleSelection = (e) => {
    const selectedIdData = [...(cat1?.options || []), ...(cat2?.options || [])].find(data => data._id === e.target.value);
  
    if (selectedIdData) {
      onSelect(selectedIdData);
    }
  };

  return (
    <FormControl className={classes['.MuiFormControl-root']}>
      <Box display='flex'>
        <Typography className={classes.label}>
          {label}
        </Typography>
        &nbsp;
        {
          hasNotification && showNotification ?
            <BootstrapTooltip title={'There is a new assessment added'} placement='bottom'>
              <CircleIcon style={{ color: '#FFC500', fontSize: '12px' }} />
            </BootstrapTooltip>
            : null
        }
      </Box>
      {
        loading ? <SelectSkeleton /> :
        <TitleTooltip
          placement='bottom' 
          component= {value?.name}
          disableHoverListener={value?.name?.length < 22}
          positionStyle={{ top: '-10px !important', left: '0px !important' , zIndex:0 }}
        >
          <MuiSelect
            onChange={handleSelection}
            value={value?._id}
            sx={{ minWidth: 200, ...(props.sx ? props.sx : {}) }}
            inputProps={{ className: classes.input }}
            MenuProps={menuProps}
            className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} ${classes['.MuiSelect-select']}`}
            {...props}
            id="grouped-select"
          >
            {
              cat1?.options?.length && cat2?.options?.length &&
              <ListSubheader className={classes.category}>{cat1?.category}</ListSubheader>
            }
            {
              cat1?.options?.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  <Box className={classes.overflowText}>
                    <Typography 
                      className={`${classes.truncatedTypography} ${option.name.length > 22 && value?._id !== option._id ? classes.overflown : ''}`}
                    >
                        {option.name}&nbsp;
                        {
                          option?.hasNotification && showNotification
                            ? <CircleIcon style={{ color: '#FFC500', fontSize: '12px' }} /> : null
                        }
                    </Typography>
                  </Box>
                </MenuItem>
              ))
            }
            {
              cat1?.options?.length && cat2?.options?.length &&
              <ListSubheader className={classes.category}>{cat2?.category}</ListSubheader>
            }
            {
              cat2?.options?.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                   <Box className={classes.overflowText}>
                    <Typography 
                      className={`${classes.truncatedTypography} ${option.name.length > 22 && value?._id !== option._id ? classes.overflown : ''}`}
                    >
                      {option.name}&nbsp;
                      {
                        option?.hasNotification && showNotification
                          ? <CircleIcon style={{ color: '#FFC500', fontSize: '12px' }} /> : null
                      }
                    </Typography>
                  </Box>
                </MenuItem>
              ))
            }
          </MuiSelect>
        </TitleTooltip>
      }
    </FormControl>
  );
};


export function CountryCodeSelect({ value, ...props }) {
  const classes = useStyles();

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.groupList
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
  };

  return (
    <Box>
      <Typography className={classes.largeFieldLabel}>
        {props.label || ''}
      </Typography>
      <MuiSelect
        className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} ${classes['.MuiSelect-select']}`}
        sx={{ minWidth: 200, ...(props.sx ? props.sx : {}) }}
        inputProps={{ className: classes.input }}
        MenuProps={menuProps}
        variant='outlined'
        value={value}
        renderValue={(selected) => {
          return `+${selected}`
        }}
        {...props}
      >
        {
          countries.map((option) => (
            <MenuItem key={option.id} value={option.code}>
              {option.label}
            </MenuItem>
          ))
        }
      </MuiSelect>
    </Box>

  );
}

export function RadioSelect({
  options, value, onChange = () => { }, label = null, optionWidth = 366,
  radioSize = 'medium', labelVariant = 'body01-bold', documentSelect = false, ...props
}) {
  const classes = useStyles({documentSelect});

  return (
    <FormControl>
      {label && <Typography mb={2} variant='h5-medium'>{label}</Typography>}
      <RadioGroup
        row
        value={value}
        onChange={onChange}
        className={classes.radioSelectContainer}
        {...props}
      >
        {options.map(option => (
          <FormControlLabel
            key={option._id}
            value={option._id}
            control={
                      <Radio 
                        size={radioSize} 
                        sx={{height:'100%', backgroundColor:theme.palette.neutral['clr-50'] , 
                            width:'48px' , borderRadius:0}}
                      />
                    }
            label={
             <Box className={classes.radioLabelConatiner}>
                {documentSelect && (
                  <Box className={classes.radioImage}>
                  <img 
                    src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/Frame_bg_radio.svg'
                    alt='bg'
                    width={104}
                    height={92}
                 />
                 <Box className={classes.optionImageBox}>
                 <img 
                    width={92} 
                    height={112}
                    src={option.img} 
                    alt={option.label} 
                    className={classes.optionImage}
                  />
                 </Box>
                </Box>
                )}
                 <Box className={classes.contentContainer}>
                  {option._id === DocumentOptions.JD_RESUME._id && (
                    <Chip 
                      content={`Most preferred`}
                      bgColor={theme.palette.tertiary['clr-500']}
                      color={theme.palette.shades['clr-white-900']}
                      typographyVariant='body02-bold'
                      sx={{
                        borderRadius: '4px',
                        padding : '4px'
                      }}
                    />
                  )}
                  <Box >
                    <Typography
                      variant={labelVariant}
                      className={`${classes.radioSelectLabel} ${value === option._id ? 'selected' : ''}`}
                    >
                        {option.label}
                        {option._id === DocumentOptions.CASE_STUDY._id && (
                          <span>
                          <Chip 
                            content={`New`}
                            bgColor={theme.palette.tertiary['clr-500']}
                            color={theme.palette.shades['clr-white-900']}
                            typographyVariant='body02-bold'
                            sx={{
                              borderRadius: '4px',
                              padding : '4px',
                              position : 'absolute',
                              right : '80px',
                              background: `linear-gradient(284.04deg, #BE93C5 0%, #7BC6CC 100%)`,
                              height : '21px'
                            }}
                          />
                          </span>
                    )} 
                    
                    </Typography>
                   
                  </Box>
                 
                  <Typography variant='overline-bold' color='neutral.clr-400'>
                    {option.subLabel}
                  </Typography>
                 </Box>
             </Box>
            }
            className={`${classes.radioSelectOption} ${value === option._id ? 'selected' : ''}`}
            style={{ width: optionWidth, margin: 0 , padding:0 }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export function AutoCompleteSelect({
  options,
  defaultValue,
  value,
  onSelect = () => {},
  label = null,
  placeholder = 'Select option',
  loading = false,
  width="100%",
  ...props
}) {
  const classes = useStyles({width});

  const [searchTerm, setSearchTerm] = React.useState('');
  const [open, setOpen] = React.useState(false);
  
  const searchInputRef = React.useRef(null);

  const handleSelection = (e) => {
    const selectedIdData = options.find(data => data._id === e.target.value)
    if(selectedIdData){
      onSelect(selectedIdData)
    }
  }
  
  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const preventDropdownClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDropdownOpen = () => {
    setOpen(true);
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 0);
  };

  const handleDropdownClose = () => {
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
  };

  const menuProps = React.useMemo(() => ({
    classes: {
      paper: classes.paper,
      list: classes.groupList
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    MenuListProps: {
      style: {
        ...props.menuStyle
      }
    }
  }), [classes.paper, classes.groupList, props.menuStyle]);

  const iconComponent = (iconProps) => (
    <ExpandMoreIcon className={`${iconProps.className} ${classes.icon}`} />
  );

  return (
    <FormControl className={classes['.MuiFormControl-root']}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <TitleTooltip
            placement='bottom' 
            component= {value?.name}
            disableHoverListener={value?.name?.length < 22}
            positionStyle={{ top: '-10px !important', left: '0px !important' , zIndex:0 }}
          >
            <MuiSelect
              displayEmpty
              className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} ${classes['.MuiSelect-select']}`}
              sx={{ minWidth: 200, ...(props.sx || {}) }}
              onChange={handleSelection}
              renderValue={(selected) => {
                const data = filteredOptions.find((option) => option._id === selected);
                return selected?.length ? data?.name : placeholder;
              }}
              value={value._id}
              defaultValue={defaultValue}
              inputProps={{ className: classes.input }}
              IconComponent={iconComponent}
              open={open}
              onOpen={handleDropdownOpen}
              onClose={handleDropdownClose}
              MenuProps={menuProps}
              disableCloseOnSelect
              {...props}
              id="select"
            >
              <ListSubheader 
                className={classes.category} 
                style={{ 
                        height: '52px', backgroundColor: theme.palette.shades['clr-white-900'],
                        padding: theme.spacing(2) ,
                        "&:hover": {
                          backgroundColor: theme.palette.shades['clr-white-900'], 
                        }
                      }}
              >
                <TextField
                  fullWidth
                  placeholder="Search here..."
                  className={classes.textField}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onClick={preventDropdownClose}
                  onFocus={() => setOpen(true)}
                  inputRef={searchInputRef}
                  InputProps={{
                    className: classes.input,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/search-icon.svg'
                          alt='search'
                          width={14}
                          height={14}
                        />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  onKeyDown={handleKeyDown}
                />
              </ListSubheader>

              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <MenuItem
                    key={option._id}
                    value={option._id}
                    disableRipple
                    disableTouchRipple
                  >
                    <Box className={classes.overflowText}>
                        <Typography
                          className={`${classes.truncatedTypography} ${option.name.length > 22 ? classes.overflown : ''}`}
                        >
                          {option.name}
                        </Typography>
                      </Box>
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled  sx={{ color: '#3C434D' }}>
                    No results were found!
                </MenuItem>
              )}
            </MuiSelect>
          </TitleTooltip>
        )}
      </>
    </FormControl>
  );
}

