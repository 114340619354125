import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import useAssessmentStore from './assessmentStore';
import { useRef } from 'react';

const useStyles = makeStyles(theme=>({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        backgroundColor: '#fff',
        borderTop: '1px solid #CCD4DE',
        gap: theme.spacing(2),
        padding: theme.spacing(2,0),
        minHeight: 80,
        justifyContent: 'center'
      },
    questions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 0, height: 0
      },
    },
    item: {
        ...theme.typography['body01-bold'],
        transition: "all 100ms ease-in-out",
        border: '2px solid',
        padding: "0px !important",
        margin: 4,
        minHeight: '52px', minWidth: '52px',
        maxHeight: '52px', maxWidth: '52px',
        "&:hover": {
            boxShadow: '0px 0px 8px 1px #003E8C4D'
        },
        "&:active": {
            transition: 'none'
        },
        "& .MuiButton-root": {
          width: 45,
          height: 45,
        }
    },
    active: {
        border: 'none',
        backgroundColor: `${theme.palette.primary['clr-500']}`,
        color: `${theme.palette.shades['clr-white-900']} !important`,
        "&:hover": {
            boxShadow: '0px 0px 8px 1px #003E8C4D'
        },
        "&:active": {
            transition: 'none'
        }
    },
    uploadingButton: {
        margin: 4,
        borderRadius: 4,
        padding: "10px !important",
        width: 45,
        height: 45,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        cursor: "not-allowed",
        transition: "all 500ms ease-in-out",
        border: "1px solid lightgrey",
        position: 'relative',
        ...theme.typography['h6-semiBold']
    },
    tooltip: {
        fontFamily: 'Inter',
        fontWeight: 600,
    }, 
    navigation: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '2px',
      border: '1px solid',
      borderColor: theme.palette.primary['clr-100'],
      borderRadius: '4px',
      "&:hover": {
        borderColor: theme.palette.primary['clr-300'],
      }
    },
    navigationDisabled: {
      borderWidth: '0px',
      backgroundColor: '#E1E3E5'
    },
    slideIndicator: {
      height: '10px',
      width: '10px',
      borderRadius: '50%',
      backgroundColor: theme.palette.neutral['clr-200'],
    },
    activeSlideIndicator: {
      backgroundColor: theme.palette.primary['clr-300']
    },
    animatedIndicator: {
      animation: '$zoom 1s infinite',
      borderStyle: 'solid',
      backgroundColor: theme.palette.primary['clr-50'],
      borderColor: theme.palette.primary['clr-300']
    },
    '@keyframes zoom': {
      '0%': {
        transform: 'scale(1)',
        boderWidth: '5px',
      },
      '50%': {
        transform: 'scale(1.5)',
        boderWidth: '8px',
      },
      '100%': {
        transform: 'scale(1)',
        boderWidth: '10px',
      }
    },
    navigationKeys: {
      height: '20px', 
      width: '20px',
      color: theme.palette.primary['clr-300'],
    },
    navigationKeysDisabled: {
      color: theme.palette.neutral['clr-600'],
    }
}));

export function UploadingPageButton({ progress = 0, children }) {
    const classes = useStyles();

    return (
        <Tooltip arrow title={"Uploading your answer..."} >
            <Box className={classes.uploadingButton}>
                <Box position='absolute' width={'100%'} bottom={0} >
                    <LinearProgress
                        variant={progress === 100 ? "indeterminate" : "determinate"}
                        value={progress}
                        size={34}
                        color='primary'
                    />
                </Box>
                {children}
            </Box>
        </Tooltip>
    );
}

function PageButton({
    id, color, bgColor, selected, onClick, serialNo, disabled, uploading, progress, submitted
}) {
    const classes = useStyles();
    const ref = React.useRef();
    const theme = useTheme();

    return uploading ? (
        <UploadingPageButton progress={progress}>
            Q{serialNo}
        </UploadingPageButton>
    ) : (
        <Button
            id={id}
            ref={ref}
            style={{
              color: selected ? theme.palette.shades['clr-white-900'] 
                      : (submitted ? disabled ? '#4E5661' : color : theme.palette.shades['clr-black-900']), 
              borderColor: selected ? theme.palette.shades['clr-white-900'] : disabled ? '#E1E3E5' : color, 
              backgroundColor: selected ? (submitted ? color : theme.palette.primary.main) 
                                : disabled ? '#E1E3E5' : bgColor 
            }}
            className={selected ? `${classes.item} ${classes.active}` : classes.item}
            disabled={disabled}
            onClick={onClick}
        >
            Q{serialNo}
        </Button>
    );
}

const NavigationButton = ({ icon, onClick=()=>{}, disabled=false}) => {
  const classes = useStyles();

  return (
    <Box 
      className={`${classes.navigation} ${disabled && classes.navigationDisabled}`} 
      onClick={onClick}
    >
      {icon}
    </Box>
  )
}

function QuestionNavigation() {
    const classes = useStyles();
    const theme = useTheme();

    const quesContainerRef = useRef();
    const uploadStates = useAssessmentStore(state => state.uploadStates);
    const uploadProgresses = useAssessmentStore(state => state.uploadProgresses);
    const questionsError = useAssessmentStore(state => state.questionsError);
    const questions = useAssessmentStore(state => state.questions);
    const currentQuestion = useAssessmentStore(state => state.currentQuestion);
    const questionState = useAssessmentStore(state => state.questionState);
    const isInterviewerSpeaking = useAssessmentStore(state => state.isInterviewerSpeaking);
    const setCurrentQuestion = useAssessmentStore(state => state.setCurrentQuestion);
    const [activeSlide, setActiveSlide] = React.useState(0);

    const { quesArray } = React.useMemo(()=>{
      if (!questions || questions.length === 0) return { quesArray: [] };

      const chunkSize = 10;
      const result = [];
      
      for (let i = 0; i < questions.length; i += chunkSize) {
        result.push(questions.slice(i, i + chunkSize));
      }
      
      return { quesArray: result };
    },[questions, activeSlide])

    const getState = React.useCallback((page) => {
        const result = { 
          color: theme.palette.neutral["clr-300"], 
          bgColor: theme.palette.shades["clr-white-900"] 
        };

        result.uploading = uploadStates[page];
        result.progress = uploadProgresses[page];

        const error = questionsError[page];

        if (error !== undefined) {
            if (error){
              result.color = theme.palette.danger["clr-700"];
              result.bgColor = theme.palette.danger["clr-100"];
            } 
            else{
              result.color = theme.palette.success["clr-700"];
              result.bgColor = theme.palette.success["clr-100"];
            } 
        }

        return result;

    }, [questionsError, uploadStates, uploadProgresses]);

    React.useEffect(() => {
      const container = quesContainerRef.current;
      if (container) {
        const isOverflowing = container.scrollWidth > container.clientWidth;
        container.style.justifyContent = isOverflowing ? 'flex-start' : 'center';
      }
    }, [questions]);

    React.useEffect(()=>{
      const slideNo = Math.floor(currentQuestion/10);
      if(activeSlide !== slideNo) setActiveSlide(slideNo);
    }, [currentQuestion])

    return (
        <Box className={classes.root}>
          <Box className={classes.questions} id='interviewQuestions' ref={quesContainerRef}>
            {(quesArray?.[activeSlide] || []).map((question, index) => {
                const questionIndex = 10*activeSlide+index;
                const { uploading, progress, color, bgColor } = getState(questionIndex);
                return (
                  <PageButton
                    key={questionIndex}
                    id={`Questions${questionIndex}`}
                    selected={questionIndex === currentQuestion}
                    serialNo={questionIndex+1}
                    disabled={("RECORDING" === questionState) || isInterviewerSpeaking}
                    onClick={() => setCurrentQuestion(questionIndex)}
                    color={color}
                    bgColor={bgColor}
                    uploading={uploading}
                    progress={progress}
                    submitted={question.submitted}
                  />
                )
            })}
          </Box>
          {
            quesArray.length > 1 ?
            <Box style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}}>
              <NavigationButton
                icon={
                <KeyboardArrowLeftIcon 
                  className={
                    `${classes.navigationKeys}
                    ${activeSlide===0 && classes.navigationKeysDisabled}`}
                />}
                onClick={()=> activeSlide>0 && setActiveSlide(activeSlide-1)}
                disabled={activeSlide === 0}
              />
              {
                quesArray.map((d, index)=>{
                  return (
                    <Box
                      key={index}
                      className={
                        `${classes.slideIndicator} ${activeSlide===index && classes.activeSlideIndicator}
                          ${(Math.floor(currentQuestion/10) === index && activeSlide!==index) && classes.animatedIndicator }
                        `
                      }
                    >
                    </Box>
                  )
                })
              }
              <NavigationButton
                icon={
                  <KeyboardArrowRightIcon 
                    className={
                      `${classes.navigationKeys} 
                      ${activeSlide === quesArray.length-1 && classes.navigationKeysDisabled}`
                    }
                  />}
                onClick={()=> activeSlide < quesArray.length-1 && setActiveSlide(activeSlide+1)}
                disabled={activeSlide === quesArray.length-1}
              />  
            </Box>
            : null
          }
        </Box>
    );
}

export default QuestionNavigation;