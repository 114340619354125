import Client from "./_client";

export const getDafs = async (dafFilters = {}) => {
  let url = "/daf?";

  Object.keys(dafFilters).forEach((key) => {
    if (dafFilters[key]) url += `${key}=${dafFilters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get dafs. Please try again."
    );
  }

  return result?.data;
};