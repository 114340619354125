import React , {useEffect , useState} from 'react'
import makeStyles from "@mui/styles/makeStyles";
import { Box, Skeleton, Typography } from '@mui/material';

import AnalyticsBrief from 'components/AnalyticsBrief';
import CreateInterviewBanner from './CreateInterviewBanner';
import TodoList from './TodoList';
import PersonalizedInterviewTable from './PersonalizedInterviewTable';
import AppShell, { WhiteBox } from 'components/AppShell';
import { useServices } from 'contexts';
import { getPersonolizedTemplatesAnalytics, getPersonolizedTodoTemplates } from 'services';


const useStyles = makeStyles((theme)=>({
  interviewContainer:{
    padding: theme.spacing(7,10,10,10),
  },
  scoreCard:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(11),
  },
  scoreHead:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(1),
  },
  scoreCards: {
    display:"flex",
    gap: theme.spacing(4),
  },
  differenceBox:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    gap:theme.spacing(5),
  },
  header:{
    display:'flex',
    flexDirection:'row',
    gap:theme.spacing(1)
  },
  bottomBox:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(3)
  },
  trianglePosition:{
    position:'absolute',
    top:0,
    right:0
  },
  contentBox:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    gap:theme.spacing(3)
  },
  personalizedBox:{
    display:'flex',
    gap:theme.spacing(1),
    width:'50%'
  }
}));

const differenceDetailsData = [
  {
    "id": 1,
    img: 'https://languify-assets.s3.ap-south-1.amazonaws.com/images/PERSONALIZED.png',
    content : 'You can create and practice personalized interviews on your own. The scores won’t be considered in ‘Your Average’.'
  },
  {
    "id": 2,
    img: 'https://languify-assets.s3.ap-south-1.amazonaws.com/images/MOCK.png',
    content : 'A Mock Interview is an assessment published by the teacher or admin, and all students are required to complete it. The score will be considered in ‘Your Average.’'
  }
]

const ScoreAverage = ({
  scores,
  loading=false
}) => {

  return(
    <>
      <AnalyticsBrief 
        title='Interview Average'
        data={scores?.overallAverage}
        percentage={scores?.overallChange}
        increment={scores?.overallChange >= 0}
        subTitle='Your average score in all personalized interviews'
        info={'Reflects changes in overall average score after your last attempt'}
        loading={loading}
        attempts={scores?.attemptsCount}
      />
      <AnalyticsBrief 
        title='Content Average'
        data={scores?.contentAverage}
        percentage={scores?.contChange}
        increment={scores?.contChange >= 0}
        subTitle='Your average score for content in personalized interviews'
        info={'Reflects changes in average content score after your last attempt'}
        loading={loading}
        attempts={scores?.attemptsCount}
      />
      <AnalyticsBrief 
        title='Speech Average'
        data={scores?.communicationAverage}
        percentage={scores?.commChange}
        increment={scores?.commChange >= 0}
        subTitle='Your average score for speaking in personalized interviews'
        info={'Reflects changes in average speech score after your last attempt'}
        loading={loading}
        attempts={scores?.attemptsCount}
      />
    </>
  )
}

const PersonalisedInterview = () => {
  const classes = useStyles();
  const service = useServices();

  const [interviewTemplate , setInterviewTemplate] = React.useState(null);
  const [scores , setScores] = React.useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchScores = async () => {
    try {
      const scoresData = await getPersonolizedTemplatesAnalytics();
      setScores(scoresData)
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchPersonalizedTemplates = async () => {
      try {
        const personalizedTemplates = await service.callService(getPersonolizedTodoTemplates);
        setInterviewTemplate(personalizedTemplates) 
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPersonalizedTemplates();
    fetchScores();
  }, []);

  return (
    <> 
      <AppShell headerLabel='Interview Wizard'>
        <Box id='average-container'>
          <WhiteBox>
            <Box className={classes.scoreCard}>
              <Box className={classes.scoreHead}>
                <Typography variant='h4-medium'>Score card</Typography>
                <Typography variant='body01-bold' color='neutral.clr-600'>
                  Cumulative average scores of your personalized interviews
                </Typography>
              </Box>
              <Box className={classes.scoreCards}>
                <ScoreAverage scores={scores}/>
              </Box>
            </Box>
          </WhiteBox>

          {
            loading 
              ? <Skeleton 
                  variant='rectangular' 
                  width='100%' 
                  height={400} 
                  sx={{backgroundColor:'#FFFFFF', display:'flex' , justifyContent:'center' , 
                       alignItems:'center', color:'#BFCAD9'}}
                >
                  “The mind is not a vessel to be filled but a fire to be ignited.” 
                 <br/>
                 – Plutarch
                </Skeleton>  
              : interviewTemplate?.length === 0 
                ? <CreateInterviewBanner /> 
                : <TodoList interviewTemplate={interviewTemplate} />
          }
            
          <PersonalizedInterviewTable />

          <WhiteBox sx={{ marginTop: '16px' , position : 'relative' }}>
            <img 
              src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/cornerTriangle.png' 
              alt='triangle' 
              className={classes.trianglePosition}
            />
            <Box className={classes.differenceBox}>
              <Box className={classes.header}>
                  <Typography variant='h5-semiBold' color='primary.clr-500'>
                      You should know
                  </Typography>
                    <img 
                      width={28} 
                      height={28} 
                      src='https://languify-assets.s3.ap-south-1.amazonaws.com/gifs/Light-bulb.gif' 
                      alt='bulb' 
                    />
              </Box>
              <Box className={classes.bottomBox}>
                <Typography variant='h6-medium' color='neutral.clr-800'>
                    What’s the difference between a Personalized Interview and a Mock Interview?
                </Typography>
              </Box>
              <Box className={classes.contentBox}>
                {differenceDetailsData.map(( data ,index ) => (
                    <Box key={index} className={classes.personalizedBox}>
                      <img src={data.img} alt={data.id}/>
                      <Typography variant='body01-semiBold' color='neutral.clr-800'>
                        {data.content}
                      </Typography>
                    </Box>
                ))}
              </Box>
            </Box>                
          </WhiteBox>
        </Box>
      </AppShell>
    </>
)}

export default PersonalisedInterview
