import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useAssessmentStore from "./assessmentStore";
import { useTheme } from '@mui/material/styles';

import { useSearchParams } from "react-router-dom";
import { useLoading, useSnackbar, useServices } from "contexts";
import { useNavigateWithClient } from "hooks";
import {
    generateFollowupQuestions,
    getRandomPathwayQuestions,
    getRandomQuestionsByTemplateId
} from 'services';
import { AssessmentStates, interviewerStateTags, QuestionStates } from "./constants";
import { makeStyles } from "@mui/styles";
import Chip from "components/Chip";
import synthesizeSpeech from "utils/synthesizeSpeech";
import { templateTypes } from "components/PerformanceReview/constants";
import { Divider } from "@mui/material";
import MicTestAnimation from "components/MicTestAnimation";

const useStyles = makeStyles(theme=>({
  questionRoot: {
    display: 'flex', 
    gap: theme.spacing(3), 
    alignItems: 'center'
  },
  questionContainer: {
    overflow: 'auto',
    height: '210px',
    margin: theme.spacing(3,0),
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
      backgroundColor: theme.palette.neutral['clr-100']
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.neutral['clr-400']
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F0F5FA'
    }
  },
  question: {
    ...theme.typography['h3-semiBold'],
    textAlign: 'left',
  },
  interviewerContainer: {
    height: '60px', width: '100%',
    display: 'flex', alignItems: 'center',
    justifyContent: 'space-between'
  },
  interviewer: {
    display: 'flex', alignItems: 'center',
    gap: theme.spacing(3),
    height: '100%'
  },
  animatedTag: {
    height: '100%', 
    display: 'flex', 
    alignItems: 'center',
    gap: theme.spacing(1)
  ,},
  quesSwitchCountdown: {
    height: '44px', width: '44px',
    display: 'flex', alignItems: 'center', justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.warning['clr-100'],
    border: '2px solid',
    borderColor: theme.palette.warning['clr-300']
  },
  fadeUp: {
    animation: '$fadeUp 0.5s ease-in',
  },
  '@keyframes fadeUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  loaderContainer: {
    position: 'relative',
    height: '100%', 
  },
  loader: {
    width: '28px',
    aspectRatio: 8,
    background: 'radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(100%/3) 100% space',
    clipPath: 'inset(0 100% 0 0)',
    animation: '$l1 1s steps(4) infinite',
    position: 'absolute',
    bottom: '40%',
  },
  '@keyframes l1': {
    to: {
      clipPath: 'inset(0 -34% 0 0)',
    },
  },
}));

const InterviewerState = () => {
  const classes = useStyles();

  const isInterviewerSpeaking = useAssessmentStore(state => state.isInterviewerSpeaking);
  const questionState = useAssessmentStore(state => state.questionState);
  const setQuestionSwitch = useAssessmentStore(state => state.setQuestionSwitch);
  const questionSwitch = useAssessmentStore(state => state.questionSwitch);
  
  const [text, setText] = React.useState("");
  const [animate, setAnimate] = React.useState(false);
  const [count, setCount] = React.useState(0);

  const changeText = (text) => {
    const timer = setTimeout(() => {
      setAnimate(true);
      setText(text);
    }, 300);

    return () => clearTimeout(timer);
  };

  React.useEffect(()=>{
    changeText(
      isInterviewerSpeaking 
        ? interviewerStateTags.SPEAKING : questionState === QuestionStates.RECORDING
          ? interviewerStateTags.RECORDING :  questionSwitch
            ? interviewerStateTags.NEXT : interviewerStateTags.WAITING
    );
  },[isInterviewerSpeaking, questionState, questionSwitch]);

  React.useEffect(() => {
    if (animate) {
      const animationTimer = setTimeout(() => {
        setAnimate(false);
      }, 500); 

      return () => clearTimeout(animationTimer);
    }
  }, [animate]);

  React.useEffect(() => {
    if(questionState === QuestionStates.SUBMITTED){
      setCount(3);
      setQuestionSwitch(true);
    } 
    if (count <= 0) {
      setQuestionSwitch(false);
      return;
    }

    setTimeout(function(){
      setCount(count - 1);
    }, 1000);
  }, [count, questionState]);

  return (
    <Box mb={4}>
      <Box className={classes.interviewerContainer}>
        <Box className={classes.interviewer}>
          <img 
            src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/interviewer.png" 
            alt="interviewer"
            style={{ height: '44px', width: '44px'}}
          />
          <Box className={`${classes.animatedTag} ${animate ? classes.fadeUp : ''}`}>
            <Typography variant="h6-medium" color='neutral.clr-700'>
              {text}
            </Typography>
            <Box className={classes.loaderContainer}>
              <Box className={classes.loader}></Box>
            </Box>
          </Box>
        </Box>
        {
          !count ? null :
          <Box className={classes.quesSwitchCountdown}>
            <Typography variant='h6-semiBold'>
              {count}
            </Typography>
          </Box>
        }
      </Box>
      <Divider style={{ width: '100%' }}/>
    </Box>
  )
}

function QuestionSection() {
    const [searchParams] = useSearchParams();
    const loading = useLoading();
    const snackbar = useSnackbar();
    const service = useServices();
    const navigate = useNavigateWithClient();
    const classes = useStyles();
    const theme = useTheme();
    const audioRef = React.useRef(null);
    const typeIntervalRef = React.useRef(null);

    const template = useAssessmentStore(state => state.template);
    const [chunck, setChunck] = React.useState("");
    const currentQuestion = useAssessmentStore(state => state.currentQuestion);
    const questions = useAssessmentStore(state => state.questions);
    const setIsTimePaused = useAssessmentStore(state => state.setIsTimePaused);
    const setIsInterviewerSpeaking = useAssessmentStore(state => state.setIsInterviewerSpeaking);
    const isPersonalizedInterview = useAssessmentStore(state => state.isPersonalizedInterview);
    const questionState = useAssessmentStore(state => state.questionState);
    const setQuestionsViewed = useAssessmentStore(state => state.setQuestionsViewed);
    const questionsViewed = useAssessmentStore(state => state.questionsViewed);
    const question = React.useMemo(
      () => questions[currentQuestion], [currentQuestion, questions]
    );
    const currentQuestionRef = React.useRef({current: currentQuestion});
    const [assessmentState, setAssessmentState] = useAssessmentStore(
      state => [state.assessmentState, state.setAssessmentState]);

    const [setTemplateId, setQuestions] = useAssessmentStore(
      state => [state.setTemplateId, state.setQuestions]);
      
      const isCopyPasteEnabled = template?.proctorSettings?.copyPasteProctoring;
      
      React.useEffect(() => {
        if (assessmentState === AssessmentStates.FETCHING_QUESTIONS) {
          (async () => {
            const templateId = searchParams.get("tid");
            
            if (templateId) {
              if (!template.type) return;
              try {
                loading.show();
                let questions = [];

                      switch (template.type) {
                          case 'follow_up':
                              questions = await service.callService(
                                  generateFollowupQuestions,
                                  [...template.templatesForFollowup, templateId]
                              );
                              break;

                          case 'pathway':
                              questions = await service.callService(
                                  getRandomPathwayQuestions,
                                  {
                                      tags: template.tags,
                                      pathways: [template.pathway],
                                      numberOfQuestions: template.metadata.numberOfQuestions || 1,
                                      domains: [template.domain],
                                      masterTemplate: template?.module?.masterModule || false
                                  }
                              );
                              break;

                          case 'practice':
                          case 'assignment':
                              questions = (await service.callService(
                                  getRandomQuestionsByTemplateId,
                                  templateId
                              )).interviewQuestions;
                              break;
                          case 'personalized':
                              questions = (await service.callService(
                                  getRandomQuestionsByTemplateId,
                                  templateId, template?.metadata?.testTime || 3600
                              )).interviewQuestions;
                              break;

                          default:
                              break;
                      }

                      if (questions.length <= 0) {
                          snackbar.error(
                              `There are no questions in this template,
                              Please add some questions.`,
                          );
                          navigate("/home");
                          return;
                      }

                      setTemplateId(templateId);
                      setQuestions(questions);
                      setAssessmentState(AssessmentStates.STARTING);
                  } catch (error) {
                      console.log("DEBUG::handleBeginClick", error);
                      snackbar.error(
                          "Unable to begin assessment, Please try again!!"
                      );
                      navigate("/home");
                  } finally {
                      loading.hide();
                  }
              } else {
                  navigate("/home");
                  snackbar.warn(
                      "Please, Select a template to take an Interview!!"
                  );
              }
          })();
      }
    }, [assessmentState, searchParams, setAssessmentState, setTemplateId, setQuestions, template]);

    React.useEffect(() => {
      currentQuestionRef.current = currentQuestion;
    }, [currentQuestion]);

    const typeText = (textToType) => {
      if (typeIntervalRef.current) {
        clearInterval(typeIntervalRef.current);
        typeIntervalRef.current = null;
      }
      setChunck('');
      let charIndex = 0;

      typeIntervalRef.current = setInterval(() => {
          setIsInterviewerSpeaking(true);
          setIsTimePaused(true);
          if (charIndex < textToType.length) {
              setChunck((prevText) => prevText + textToType.charAt(charIndex));
              charIndex++;
          } else {
            setQuestionsViewed(currentQuestionRef.current,true);
            setIsInterviewerSpeaking(false);
            setIsTimePaused(false);
            clearInterval(typeIntervalRef.current);
            typeIntervalRef.current = null;
          }
      }, 60);
    };

    const reset = React.useCallback(() => {
      if (audioRef.current && !audioRef.current.paused) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
      }
    }, []);

    const toSpeech = React.useCallback(async (text = '') => {
      reset();
      typeText(text);

      const input = {
          engine: 'neural', languageCode: 'en-GB', voiceId: 'Amy', text
      };

      const { audioUrl } = await synthesizeSpeech(input);
      if (audioRef.current) {
          try {
              audioRef.current.src = audioUrl;
              audioRef.current.play();
          } catch (error) {
              console.error(error);
          }
      }
    }, []);

    React.useEffect(()=>{
      if(template?._id && assessmentState === AssessmentStates.RUNNING){
        if(template.type === templateTypes.PERSONALIZED && !questionsViewed[currentQuestion]){
          toSpeech(question?.question);
        }else{
          setChunck(question?.question);
        }
      }
    },[question, template, assessmentState]);

    return (
        <Box width='100%'>
            {
              isPersonalizedInterview && <InterviewerState/>
            }
            <Box display='flex' justifyContent='space-between'>
              <Box className={classes.questionRoot}>
                <Typography variant='h6-bold' color='primary.clr-300'>
                    Question {currentQuestion + 1} of {questions.length}
                </Typography>
                {
                  !isPersonalizedInterview &&
                  <Chip 
                    content={`${question?.type} question`}
                    bgColor={theme.palette.secondary['clr-700']}
                    color={theme.palette.shades['clr-white-900']}
                    sx={{ textTransform: 'capitalize', padding: theme.spacing(1,5) }}
                  />
                }
              </Box>
              {
                ["RECORDING"].includes(questionState) ?
                    <Box>
                        <MicTestAnimation start={true} />
                    </Box> :
                    null
              }
            </Box>
            <Box className={classes.questionContainer}>
              <audio ref={audioRef}></audio>
              <Typography
                  className={classes.question}
                  style={isCopyPasteEnabled ? {
                      userSelect: 'none',
                      WebkitTouchCallout: 'none',
                      WebkitUserSelect: 'none',
                      MozUserSelect: 'none',
                      msUserSelect: 'none',
                  } : {}}
              >
                  {chunck}
              </Typography>
            </Box>
            {question?.hint && (
                <Typography variant='body01-regular' color='neutral.clr-600'>
                    💡 Hint: {question?.hint}
                </Typography>
            )}
        </Box>
    );
}

export default QuestionSection;